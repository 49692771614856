import React from 'react';

import { StyledButton, StyledLink } from './styles/components';
import Button from 'shared/components/presentational/Button/Button';

interface CallToActionRendererProps {
  action: { type: 'hyperLink' | 'cta'; label: string; link: string };
}

export default function CallToActionRenderer({
  action
}: CallToActionRendererProps) {
  const { type, label, link } = action;

  if (type === 'hyperLink') {
    return <StyledLink to={link}>{label}</StyledLink>;
  }

  if (type === 'cta') {
    return (
      <StyledButton to={link}>
        <Button.children.Text>{label}</Button.children.Text>
      </StyledButton>
    );
  }

  return null;
}
