import styled from 'styled-components';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import Picture, { BaseWrapper } from 'shared/components/presentational/Picture';
import { COLORS, FONT_WEIGHTS } from 'shared/constants/theme';
import Button from 'shared/components/presentational/Button';
import BaseLink from 'shared/components/presentational/CustomLink/CustomLink';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);

  ${mediaQueries.fromTablet`
      gap: 0;
  `};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);

  ${mediaQueries.fromTablet`
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0;
  
  
      &:nth-child(even) {
        grid-template-areas: 
          "text image";
      }
  
      &:nth-child(odd) {
        grid-template-areas: 
          "image text";
      }
    `};
`;

export const ImageContainer = styled.div`
  grid-area: image;
`;

export const Link = styled(BaseLink)`
  width: 100%;
`;

export const CustomPictureWrapper = styled(BaseWrapper).attrs({})`
  height: 100%;
`;

export const Image = styled(Picture).attrs({
  customWrapper: CustomPictureWrapper
})`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const TextContainer = styled.div`
  grid-area: text;
  display: flex;
  gap: var(--spacing-m);
  padding: 0 var(--spacing-xl);
  flex-direction: column;
  justify-content: center;

  ${mediaQueries.fromTablet`
      padding: var(--spacing-xl) 60px;
    `};
`;

export const Title = styled.h2`
  color: ${COLORS.white};
  font-size: var(--text-size-h2);
  line-height: var(--line-height-xl);
  margin: 0;
`;

export const Description = styled.p`
  font-size: var(--text-size-base);
  color: ${COLORS.silverChalice};
  line-height: var(--line-height-h3);
`;

export const StyledLink = styled(BaseLink)`
  font-size: var(--text-size-base);
  font-weight: ${FONT_WEIGHTS.demi};
  color: ${COLORS.amaranth};
  text-decoration: none;

  &:hover {
    color: var(--primary-hover);
  }
`;

export const StyledButton = styled(Button)`
  background-color: var(--primary-default);
  border: none;
  border-radius: var(--border-radius-xs);
  height: 48px;
  width: 100%;
  min-width: 189px;
  padding: var(--spacing-xs) var(--spacing-m);

  ${mediaQueries.fromDesktop`
      width: fit-content;
      min-width: 214px;
      height: 48px;
    `}

  &:hover {
    background-color: var(--primary-hover);
  }
`;
