import React from 'react';

import {
  Wrapper,
  Container,
  ImageContainer,
  Link,
  Image,
  TextContainer,
  Title,
  Description
} from '../styles/components';
import CallToActionRenderer from '../CallToActionRenderer';
import { FormattedPropsResponse } from '../types';

export default function AlternatingPresentation({
  items
}: FormattedPropsResponse) {
  return (
    <Wrapper>
      {items.map((item, index) => {
        return (
          <Container key={index}>
            <ImageContainer>
              {item.banner.link ? (
                <Link to={item.banner.link}>
                  <Image responsivesUrls={item.banner.pictures} />
                </Link>
              ) : (
                <Image responsivesUrls={item.banner.pictures} />
              )}
            </ImageContainer>
            <TextContainer>
              <Title>{item.title}</Title>
              <Description>{item.description}</Description>
              {item.callToAction && (
                <CallToActionRenderer action={item.callToAction} />
              )}
            </TextContainer>
          </Container>
        );
      })}
    </Wrapper>
  );
}
